<template lang="pug">
.statistics
  v-container
    v-row.mt-10
      v-col(cols="12")
        h5.normal_title_h5 隊內排名
    v-row.mt-8
      v-col.position-relative(
        cols="12",
        sm="6",
        md="4",
        v-for="statLeader in playerLeaders",
        :key="statLeader.title"
      )
        players-ranking-card(
          :statLeader="statLeader",
          @playerClick="playerClick"
        )
  v-container.ranking_league_bar
    v-row.mt-10
      v-col(cols="12")
        h5.normal_title_h5 聯盟排名
    v-row(align="end")
      v-col.offset-1.offset-sm-0.offset-md-1(cols="10", sm="6", md="5")
        high-chart-polar(
          v-if="polarObject.averagePercentList.length > 0",
          :personalList="polarObject.personalList",
          :personalPercentList="polarObject.personalPercentList",
          :averageList="polarObject.averageList",
          :averagePercentList="polarObject.averagePercentList",
          :bestList="polarObject.bestList",
          :personalSeriesTitle="'隊伍'"
        )
      v-col.offset-1.offset-sm-0.offset-md-0.offset-lg-1.mt-8.mt-sm-0(
        cols="10",
        sm="5",
        md="5",
        lg="4",
        xl="3"
      )
        position-field(
          width="425px",
          :positionList="shotPositionList",
          :mainColor="'#487AA4'",
          :subColor="'#96B9DC'",
          @positionClick="positionClick"
        )

    v-row.mt-15
      v-col.px-5(
        cols="12",
        md="6",
        v-for="groupLeader in teamLeaders",
        :key="groupLeader.group_title"
      )
        h5.normal_title_h5.text-center {{ groupLeader.group_title }}
        .bar.d-flex.align-center.mt-5(
          v-for="leader in groupLeader.leaders",
          :key="leader.stat_title"
        )
          .title.text-body-1.font-weight-bold.d-inline-block.ml-4 {{ leader.stat_title }}
          .ranking.text-center.ranking_mark(
            :class="[leader.rank <= 3 ? 'text_secondary' : 'lightGray']"
          ) {{ getRankText(leader.rank) }}
          percentage-bar(
            :value="leader.value",
            :deno="leader.max_value",
            :percent="leader.show_percent_symbol"
          )
  v-container.table_container
    v-row.justify-space-between
      v-col(cols="auto")
        h5.normal_title_h5 球隊數據
    v-row.table_row
      v-col.table_col.table.position-relative.mt-5(cols="12")
        table
          thead.top_head
            sticky-column.bdr2(colspan="1", :offset="0")
            th(colspan="1")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="2")
          thead.bottom_head
            sticky-column.bdr2(colspan="1", title="", :offset="0")
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              :class="{ bdr: header.border }"
            )
              span {{ header.name }}
          tbody(v-if="teamStats.overall")
            tr
              sticky-column.bdl.bdr2(
                colspan="1",
                :offset="0",
                :title="teamStats.overall.title"
              )
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, teamStats.overall[header.key], true)",
                @click="dataClick(header.key, header.link)"
              )
                span {{ teamStats.overall[header.key] }}
    v-row.table_row
      v-col.table_col.table.position-relative.mt-5(cols="12")
        table
          thead.top_head
            sticky-column.bdr2(colspan="1", :offset="0")
            th(colspan="1")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="2")
          thead.bottom_head
            sticky-column.bdr2(colspan="1", title="", :offset="0")
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              :class="{ bdr: header.border }"
            )
              span {{ header.name }}
          tbody(v-if="teamStats.location")
            tr(v-for="stats in teamStats.location", :key="stats.title")
              sticky-column.bdl.bdr2(
                colspan="1",
                :offset="0",
                :title="stats.title"
              )
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, stats[header.key], false)"
              )
                span {{ stats[header.key] }}
    v-row.table_row
      v-col.table_col.table.position-relative.mt-5(cols="12")
        table
          thead.top_head
            sticky-column.bdr2(colspan="1", :offset="0")
            th(colspan="1")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="2")
          thead.bottom_head
            sticky-column.bdr2(colspan="1", title="", :offset="0")
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              :class="{ bdr: header.border }"
            )
              span {{ header.name }}
          tbody(v-if="teamStats.outcome")
            tr(v-for="stats in teamStats.outcome", :key="stats.title")
              sticky-column.bdl.bdr2(
                colspan="1",
                :offset="0",
                :title="stats.title"
              )
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, stats[header.key], false)"
              )
                span {{ stats[header.key] }}
    v-row.table_row
      v-col.table_col.table.position-relative.mt-5(cols="12")
        table
          thead.top_head
            sticky-column.bdr2(colspan="1", :offset="0")
            th(colspan="1")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="2")
          thead.bottom_head
            sticky-column.bdr2(colspan="1", title="", :offset="0")
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              :class="{ bdr: header.border }"
            )
              span {{ header.name }}
          tbody(v-if="teamStats.vs_team")
            tr.hover.clickable(
              v-for="stats in teamStats.vs_team",
              :key="stats.title",
              @click="teamClick(stats.team_id)"
            )
              sticky-column.bdl.bdr2(
                colspan="1",
                :offset="0",
                :title="stats.title"
              )
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, stats[header.key], false)"
              )
                span {{ stats[header.key] }}

  event-video-dialog(
    v-model="isDialogShow",
    :seasonSegmentId="seasonSegmentId",
    :teamId="teamId",
    :position="position",
    :stat="stat"
  )
</template>

<script>
import { getTeamStatistics } from "@/api/league";
import PlayersRankingCard from "@/components/league/PlayersRankingCard";
import PercentageBar from "@/components/common/PercentageBar";
import PositionField from "@/components/common/PositionField";
import HighChartPolar from "@/components/common/HighChartPolar";
import StickyColumn from "@/components/common/Table/StickyColumn";
import EventVideoDialog from "@/components/league/EventVideoDialog";

export default {
  name: "LeagueTeamStatistics",
  components: {
    PlayersRankingCard,
    StickyColumn,
    PercentageBar,
    PositionField,
    HighChartPolar,
    EventVideoDialog,
  },
  data() {
    return {
      playerLeaders: [],
      polarObject: {
        bestList: [],
        personalList: [],
        personalPercentList: [],
        averageList: [],
        averagePercentList: [],
      },
      shotPositionList: [],
      teamLeaders: [],

      boxTableHeaders: [
        {
          name: "場次",
          key: "gp",
          border: true,
          bold: false,
          link: false,
        },
        {
          name: "得分",
          key: "pts",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "籃板",
          key: "reb",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "助攻",
          key: "ast",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "抄截",
          key: "stl",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "阻攻",
          key: "blk",
          border: true,
          bold: true,
          link: true,
        },
        {
          name: "命中",
          key: "fgm",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fga",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg2m",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fg2a",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg2_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg3m",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fg3a",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg3_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "ftm",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fta",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "ft_pct",
          border: true,
          bold: false,
        },
        {
          name: "進攻",
          key: "oreb",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "防守",
          key: "dreb",
          border: true,
          bold: false,
          link: true,
        },
        {
          name: "失誤",
          key: "tov",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "犯規",
          key: "pf",
          border: true,
          bold: false,
          link: true,
        },
      ],
      teamStats: {},

      isDialogShow: false,
      position: "",
      stat: "",
    };
  },
  computed: {
    leagueId() {
      return Number(this.$route.params.leagueId);
    },
    seasonSegmentId() {
      return Number(this.$route.params.seasonSegmentId);
    },
    teamId() {
      return Number(this.$route.params.teamId);
    },
  },
  watch: {
    seasonSegmentId() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      if (this.leagueId < 1 || this.seasonSegmentId < 1 || this.teamId < 1)
        return;
      await this.getTeamStatisticsApi();
    },
    async getTeamStatisticsApi() {
      const params = {
        team_id: this.teamId,
        season_segment_id: this.seasonSegmentId,
      };
      const response = await getTeamStatistics(params);
      this.playerLeaders = response.data.player_leaders;
      this.polarObject.bestList = response.data.polar_chart.best;
      this.polarObject.personalList = response.data.polar_chart.personal;
      this.polarObject.personalPercentList =
        response.data.polar_chart.personal_percent;
      this.polarObject.averageList = response.data.polar_chart.average;
      this.polarObject.averagePercentList =
        response.data.polar_chart.average_percent;
      this.shotPositionList = response.data.shot_position;
      this.teamLeaders = response.data.team_leaders;
      this.teamStats = response.data.team_stats;
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          leagueId: this.leagueId,
          playerId: player.player_id,
          seasonSegmentId: this.seasonSegmentId,
        },
      });
    },
    positionClick(position) {
      this.stat = "";
      this.position = position;
      this.isDialogShow = true;
    },
    getDataClassObject(header, value, isOverall = false) {
      let classObject = {};
      if (isOverall) {
        if (header.link && header.bold) classObject.link_deep = true;
        else if (header.link) classObject.link = true;
        else if (value <= 0) classObject.gray_out_heavy = true;
      } else {
        if (value > 0 && header.bold) classObject.blue_heavy = true;
        else if (value <= 0) classObject.gray_out_heavy = true;
      }
      classObject.bdr = header.border;
      return classObject;
    },
    dataClick(stat, link) {
      if (!link) return;
      this.stat = stat;
      this.position = "";
      this.isDialogShow = true;
    },
    teamClick(teamId) {
      this.$router.push({
        name: "LeagueTeam",
        params: {
          leagueId: this.leagueId,
          teamId: teamId,
          seasonSegmentId: this.seasonSegmentId,
        },
      });
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/dialog_play_video";
@import "@/assets/style/common/pagination";

.img_circle {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.ranking_list {
  li {
    height: 42px;
    border-bottom: #d2d2d2 1px solid;

    &:first-child {
      color: white;
      background-color: #002442;
      border-bottom: none;

      .number {
        text-align: right;
        flex-grow: 7;
      }

      .player {
        flex-grow: 5;
        margin-left: 10px;
      }

      .score {
        font-family: Helvetica;
        font-size: 24px;
        font-style: italic;
        font-weight: bold;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .ranking {
      flex-grow: 0;
    }

    .number {
      flex-grow: 3;
      text-align: center;
    }

    .player {
      flex-grow: 5;
    }

    .score {
      flex-grow: 4;
    }
  }
}

.ranking_league_bar {
  .title {
    flex-shrink: 0;
    min-width: 74px;
  }

  .ranking_mark {
    margin-right: 16px;
    flex-shrink: 0;
  }
}

.table_container {
  margin-top: 100px;
}

input,
textarea,
select {
  background: #ffffff;
  border: 1px solid #487aa4;
  // @include font(16px, #a473e0, 300);
}

input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/arrow_bottom_s.png") no-repeat right center;
  background-position: calc(100% - 40px) center;
}

.theme--light.v-sheet {
  li {
    .ranking {
      color: $lightGray;
    }

    &:first-child {
      .ranking {
        color: white;
      }
    }
  }
}
</style>
