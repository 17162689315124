<template lang="pug">
.ranking_card.mb-12
  v-card.elevation-2.ranking_list.mt-5.d-flex.flex-column.w-100(tile)
    li.d-flex.align-center.flex-grow-1.rounded-sm.px-5.py-2.position-relative(
      v-for="(player, index) in statLeader.leaders",
      @click="playerClick(player)",
      :key="index"
    )
      .ranking.text-center.flex-grow-5 {{ getRankText(index + 1) }}

      span.number {{ "#" + player.jersey_number }}
      span.player {{ player.name }}
      span.score.text-right {{ getValue(player) }}
  .card.position-absolute.top-0(style="right: 0; right: 15px")
    span.text_value {{ statLeader.title_en }}
    span.font-weight-bold.ml-2(style="color: #ababab") {{ statLeader.title }}
</template>

<script>
export default {
  name: "PlayersRankingCard",
  props: {
    statLeader: Object,
  },
  data() {
    return {
      rankingTab: null,
    };
  },
  methods: {
    playerClick(player) {
      this.$emit("playerClick", player);
    },
    getValue(player) {
      return this.statLeader.show_percent_symbol
        ? player.value + "%"
        : player.value;
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img_circle {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.ranking_list {
  li {
    height: 42px;
    border-bottom: #d2d2d2 1px solid;
    cursor: pointer;

    &:first-child {
      color: white;
      background-color: #002442;
      border-bottom: none;

      .number {
        text-align: right;
        flex-grow: 1;
        flex-shrink: 1;
        // flex-basis: 110px;
      }

      .player {
        flex-grow: 5;
        margin-left: 10px;
      }

      .score {
        font-family: Helvetica;
        font-size: 24px;
        font-style: italic;
        font-weight: bold;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .ranking {
      flex-grow: 0;
      flex-basis: 30px;
    }

    .number {
      flex-grow: 3;
      text-align: center;
    }

    .player {
      flex-grow: 5;
    }

    .score {
      flex-grow: 4;
    }
  }
}

.text_value {
  font-style: italic;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 20px;
}

.theme--light.v-sheet {
  li {
    .ranking {
      color: $lightGray;
    }

    &:first-child {
      .ranking {
        color: white;
      }
    }
  }
}

@media (max-width: 599px) {
  .ranking_list {
    li {
      height: 38px;

      .number,
      .ranking,
      .score,
      .player {
        font-size: 14px;
      }

      &:first-child {
        .score {
          font-size: 20px;
        }
      }
    }
  }

  .text_value {
    font-size: 18px;
  }
}
</style>
